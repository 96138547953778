import Vuex from 'vuex';
import Vue from 'vue';
Vue.use(Vuex);
import {emailStore} from "@components/emails/store";
import {orderStore} from '@orders/store';
import {offerStore} from "@orders/subpages/Offer/store";
import {axiosByMethod, post} from "@api";
import {notifyError} from "@components/Notification";
import {currentUser, extractBodyContent, extractJson, replaceBodyContent} from "@utilities/functions";

const aiStore = {
    state: {
        isSending: false,
        promptText: '',
        promptIsDisabled: true,
        data: {
            model: "gpt-4o",
            messages: [],
        },
        suggestions: {
            chat: {
                clear: true,
                model: "gpt-4o",
                label: 'ChatGPT',
                messages: [
                    {
                        "key": 'chat' + new Date(),
                        "forApi": true,
                        "isVisible": false,
                        "role": "system",
                        "content": "Answer should be formatted in html with inline style attributes. Do NOT change family, size and color of the font.",
                    },
                    {
                        "key": 'write-1-' + new Date(),
                        "forApi": false,
                        "isVisible": true,
                        "role": "assistant",
                        "content": "Was kann ich für dich tun?",
                    },
                ],
                callAction: ['enableChat']
            },
            writeEmailFromInput: {
                clear: true,
                label: 'Text formulieren',
                messages: [
                    {
                        "key": 'french-1-' + new Date(),
                        "forApi": true,
                        "isVisible": false,
                        "role": "system",
                        "content": "Answer should be formatted in html with inline style attributes. Do NOT change family, size and color of the font. Write a text based on the input of the user",
                    },
                    {
                        "key": 'write-1-' + new Date(),
                        "forApi": true,
                        "isVisible": true,
                        "role": "assistant",
                        "content": "Bitte gib mir einige Stichworte oder Anweisungen, damit ich weiß worüber ich einen Text schreiben soll.",
                    },
                ],
                callAction: ['enableChat']
            },
            french: {
                label: 'Französisch',
                messages: [
                    {
                        "response_format": {type: "json"},
                        "key": 'french-1-' + new Date(),
                        "forApi": true,
                        "isVisible": true,
                        "role": "user",
                        "content": "Bitte Email ins Französische übersetzen",
                    },
                ],
                callAction: ['addEmailToChat'],
            },
            english: {
                label: 'Englisch',
                messages: [
                    {
                        "response_format": {type: "json"},
                        "key": 'french-1-' + new Date(),
                        "forApi": true,
                        "isVisible": true,
                        "role": "user",
                        "content": "Bitte Email ins Englische übersetzen",
                    },
                ],
                callAction: ['addEmailToChat'],
            },
            checkGrammar: {
                label: 'Rechtschreibung prüfen',
                clear: true,
                model: "gpt-4o",
                messages: [
                    {
                        "response_format": {type: "json"},
                        "key": 'message-check-Email-1-' + new Date(),
                        "forApi": true,
                        "isVisible": false,
                        "role": "system",
                        "content": "Please correct spelling and grammar of the properties \"textHtml\"  and \"subject\" of a JSON object. Return your answer in the same JSON format without additional text.",
                    },
                    {
                        "key": 'message-check-Email-2-' + new Date(),
                        "forApi": false,
                        "isVisible": true,
                        "role": "user",
                        "content": "Bitte Email auf Rechtschreibung und Grammatik überprüfen",
                    },
                ],
                callAction: ['addEmailToChat'],

            },
            translateOrder: {
                label: 'Auswahl übersetzen',
                clear: true,
                model: "gpt-4o",
                messages: [],
                callAction: ['translateOrder'],
            },
            translateEmail: {
                label: 'Email übersetzen',
                clear: true,
                model: "gpt-4o",
                messages: [
                    {
                        "response_format": {type: "json"},
                        "key": 'message-translate-Email-1-' + new Date(),
                        "forApi": true,
                        "isVisible": false,
                        "role": "system",
                        "content": "Translate the properties \"textHtml\" and \"subject\" of a JSON object. Return your answer in the same JSON format without additional text.",
                    },
                    {
                        "response_format": {type: "json"},
                        "key": 'message-translate-Email-2-' + new Date(),
                        "forApi": false,
                        "isVisible": true,
                        "role": "user",
                        "content": "Ich möchte meine Email übersetzen",
                    },
                    {
                        "key": 'message-translate-Email-3-' + new Date(),
                        "forApi": false,
                        "isVisible": true,
                        "role": "assistant",
                        "content": "In welche Sprache soll die Email übersetzt werden (Achtung aktuell Email wird überschrieben)?",
                        suggestions: [
                            'french', 'english'/*, 'german', 'english', 'spanish'*/
                        ],
                    },
                ]
            }
        },
        startHelp: {
            default: {
                messages: [
                    {
                        "key": 'message-start-default-1-' + new Date(),
                        "forApi": false,
                        "isVisible": true,
                        "role": "assistant",
                        "content": "Hallo " + (currentUser() ? currentUser().firstName: '') + "! \n" + "Ich bin noch in der Beta-Phase und habe für diese Seite noch keine speziellen Funktionen. Du kannst aber mit mir chatten und so die normalen Chat-GPT-Funktionen nutzen.",
                        suggestions: [
                            'chat',/*, 'writeEmailFromInput', 'writeResponseFromFAQ', 'changeTone', 'checkGrammar'*/
                        ],
                    },
                ],
            },
            email: {
                messages: [
                    {
                        "key": 'message-start-email-2-' + new Date(),
                        "forApi": false,
                        "isVisible": true,
                        "role": "assistant",
                        "content": "Hallo " + (currentUser() ? currentUser().firstName: '') + "! Ich bin noch in der Beta Phase, aber sehe, dass du eine Email schreiben möchtest. Vielleicht kann ich dir dabei helfen?",
                        suggestions: [
                            'translateEmail', 'checkGrammar', 'writeEmailFromInput', 'chat'/*, 'writeEmailFromInput', 'writeResponseFromFAQ', 'changeTone', 'checkGrammar'*/
                        ],
                    },
                ],
            },
            orderTranslation: {
                messages: [
                    {
                        "key": 'message-start-email-2-' + new Date(),
                        "forApi": false,
                        "isVisible": true,
                        "role": "assistant",
                        "content": "Hallo " + (currentUser() ? currentUser().firstName: '') + "! Ich werde dir bei der Übersetzung der freien Texte helfen. Bitte wähle die Elemente aus, die du übersetzen möchtest. Achtung: Die ausgewählten Elemente werden mit der Übersetzung überschrieben.",
                        checkboxes: [
                            'Tagesüberschriften', 'Freitexte in den Tagen', 'Reisetitel', 'Freitexte in der Kalkulation', 'Hinweise im Anschreiben', 'Ausschreibungstexte'
                        ],
                        checkBoxValues: ['Tagesüberschriften', 'Freitexte in den Tagen', 'Reisetitel', 'Freitexte in der Kalkulation', 'Hinweise im Anschreiben'],
                        suggestions: [
                            'translateOrder'
                        ],
                    },
                ],
            },
            clientInvoiceTranslation: {
                messages: [
                    {
                        "key": 'message-start-client-invoice' + new Date(),
                        "forApi": false,
                        "isVisible": true,
                        "role": "assistant",
                        "content": "Hallo " + (currentUser() ? currentUser().firstName: '') + "! Ich werde dir bei der Übersetzung der freien Texte helfen. Bitte wähle die Elemente aus, die du übersetzen möchtest. Achtung: Die ausgewählten Elemente werden mit der Übersetzung überschrieben.",
                        checkboxes: [
                            'Freitexte in der Kundenrechnung'
                        ],
                        checkBoxValues: ['Freitexte in der Kalkulation'],
                        suggestions: [
                            'translateOrder'
                        ],
                    },
                ],
            }
        },
    },


    mutations: {

    },

    actions: {
        enableChat({state}) {
            state.promptIsDisabled = false;
        },

        sendPrompt({ state, dispatch }) {
            state.isSending = true;
            state.data.messages.push(
                {
                    "key": 'prompt-' + new Date(),
                    "forApi": true,
                    "isVisible": true,
                    "role": "user",
                    "content": state.promptText,
                }
            );
            state.promptText = '';
            dispatch('chat');
        },

        sendRequestToAI({state}) {
            return post('open_ai/chat', {...state.data, messages: state.data.messages.filter(message => message.forApi)})
        },

        makeApiCall(state, {method, api, data = {}, params = {}, config = {}}) {
            return axiosByMethod(method, api, data, params, config)
        },

        chat({ state, dispatch }) {
            state.isSending = true;
            dispatch('sendRequestToAI').then(response => {
                state.isSending = false;
                state.data.messages.push({...response.data.choices[0].message, isVisible: true, forApi: true, key: new Date()})
            }, error => {
                state.isSending = false;
                notifyError('Server Error! Es konnte keine Verbindung mit ChatGpt hergestellt werden!')
            });
        },

        translateOrder ({ state, commit, dispatch, rootState }) {
            state.isSending = true;
            let orderId = rootState.orderStore.orderID;
            if(orderId) {
                dispatch('makeApiCall', {
                    method: 'PUT',
                    api: 'orders/' + orderId + '/translate',
                    params: {
                        _groups: ['trip_read', 'order_offer_read'],
                        toTranslate: state.data.messages[0].checkBoxValues
                    }
                }).then(response => {
                    rootState.orderStore.order.trip.name = response.data.trip.name;
                    rootState.offerStore.offer.introText = response.data.mainOffer.introText;
                    state.isSending = false;
                    state.data.messages.push({
                        "key": 'translate-order-' + new Date(),
                        "forApi": false,
                        "isVisible": true,
                        "role": "assistant",
                        "content": "Übersetzung erfolgreich.",
                    });
                })
            }

        },

        addEmailToChat({ state, commit, dispatch, rootState }) {

            state.data.messages.push(
                {
                    "response_format": {type: "json"},
                    "key": 'addEmailToChat-2' + new Date(),
                    "forApi": true,
                    "isVisible": false,
                    "role": "user",
                    "isEmail": true,
                    "content": JSON.stringify({textHtml: extractBodyContent(rootState.emailStore.newEmail.textHtml), subject: rootState.emailStore.newEmail.subject}),
                }
            )
            state.isSending = true;
            rootState.emailStore.isLoadingPreview = true;
            rootState.emailStore.emailManuallyChanged = true;

            dispatch('sendRequestToAI').then(response => {
                let email = JSON.parse(extractJson(response.data.choices[0].message.content))
                rootState.emailStore.newEmail.subject = email.subject
                rootState.emailStore.newEmail.textHtml = replaceBodyContent(rootState.emailStore.newEmail.textHtml, email.textHtml);
                rootState.emailStore.isLoadingPreview = false;
                state.isSending = false;
                state.data.messages = [{
                    "key": 'done-1' + new Date(),
                    "forApi": false,
                    "isVisible": true,
                    "role": "assistant",
                    "content": "Aufgabe erledigt!",
                }];
                state.data.messages.push(...state.startHelp.email.messages)
            });
        }
    },

    getters: {

    }
}
const store = new Vuex.Store({
    modules: {
        emailStore: emailStore,
        aiStore:aiStore,
        orderStore: orderStore,
        offerStore: offerStore,
    }
});



export { store };

export default store;
